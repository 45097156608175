import styled from 'styled-components';

import Checkbox from '../../Elements/Form/Checkbox/Checkbox';
import {colors} from '../../../styles/variables';

export const FormWrapper = styled.div`
	position: fixed;
	bottom: 0;
	background-color: ${colors.lightgrey};
	z-index: 999;
	width: 100%;
`;

export const SubmitWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

export const TextWrapper = styled.div`
	padding-bottom: 120px;
`;
export const StyledCheckBox = styled(Checkbox)`
	font-size: 12px;
`;
