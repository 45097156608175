import React from 'react';

import Headline from '../../components/Elements/Headline/Headline';
import Text from '../../components/Elements/Text/Text';

export const privacyPolicy = (
	<>
		<Headline as="h3" text="Datenschutzhinweis und Einwilligungserklärung" />
		<Headline as="h4" text="Einführung" />
		<Text content="Biogen GmbH, Riedenburger Straße 7, 81677 München, Deutschland („Biogen“) ist für die Website https://mein.ms-life.de/ verantwortlich. Bei der Website handelt es sich um ein Informationsportal für Betroffene und Angehörige zum Thema Multiple Sklerose. In diesem Datenschutzhinweis wird erklärt, wie Biogen deine personenbezogenen Daten (d. h. Daten, die mit deiner Person und deinen persönlichen Umständen in Zusammenhang stehen) auf der Website verarbeiten möchte und wie du in diese Verarbeitung einwilligen kannst. Deine Einwilligung ist völlig freiwillig und kann jederzeit widerrufen werden. Wenn du beschließt, deine Einwilligung nicht zu erteilen, entstehen dir daraus keinerlei nachteilige Folgen. Allerdings kannst du dich dann nicht für diese Website registrieren. Wenn du fortfahren möchtest, lies dir bitte die folgenden Informationen sorgfältig durch und willige ein, indem du bei der Registrierung das entsprechende Kästchen aktivierst." />
		<Headline as="h4" text="Website" />
		<Text content="Du kannst die folgenden personenbezogenen Daten auf die Website hochladen:" />
		<ol>
			<li>Vor- und Nachname</li>
			<li>E-Mail-Adresse</li>
			<li>Benutzername und Kennwort</li>
			<li>Meinungen/Kommentare</li>
			<li>Weg der Kontaktaufnahme</li>
		</ol>
		<Text content="Diese personenbezogenen Daten werden benötigt, um:" />
		<ol>
			<li>
				auf der Website ein Nutzerkonto für dich zu erstellen, damit du dich einloggen und die Funktionen der
				Website nutzen kannst sowie den Status des MS-Begleitprogramms zu validieren
			</li>
			<li>Beiträge und Rezepte zu deinen Favoriten hinzuzufügen und als solche zu speichern</li>
		</ol>
		<Headline as="h4" text="Weitergabe deiner Daten und internationale Datentransfers" />
		<Text
			content={`<b>Dienstleister:</b>  Biogen beauftragt Dienstleister, die Biogen in Zusammenhang mit der Website bei der Verwaltung der Datenverarbeitung unterstützen (und als „Auftragsverarbeiter“ bezeichnet werden). Dazu gehören Unternehmen, die (a) die Website und deine personenbezogenen Daten in der Cloud hosten und Rechenzentren für Biogen zur Verfügung stellen, (b) Biogen bei der Verwaltung der Nutzerdaten und beim Usermanagement unterstützen, (c) einen technischen Support für die Website bereitstellen. `}
		/>
		<Text
			content={`<b>Arzneimittelüberwachung und gesetzliche Bestimmungen:</b> Biogen ist gesetzlich dazu verpflichtet, behördliche Bestimmungen einzuhalten, die für Biogen als pharmazeutisches Unternehmen gelten. Dazu gehört u. a. die Pflicht, Nebenwirkungen der unternehmenseigenen Produkte zu melden, um die Sicherheit sämtlicher Arzneimittel, die wir vermarkten, zu überwachen. (Dies wird als Pflicht zur Arzneimittelüberwachung und zur Meldung von Nebenwirkungen bezeichnet.) Biogen kann deine personenbezogenen Daten erheben, nutzen und gegenüber anderen Mitgliedern der Biogen-Unternehmensgruppe oder anderen Dritten offenlegen, wenn Biogen dies als erforderlich erachtet, um die geltenden Gesetze einzuhalten, die wesentlichen Interessen von Biogen oder einer anderen Person zu schützen oder die Rechte, die Biogen gesetzlich zustehen, geltend zu machen, zu erwirken oder zu verteidigen. `}
		/>
		<Text
			content={`<b>Sicherstellung des Schutzes deiner Daten: </b> Die oben angesprochene Weitergabe von Daten kann auch eine Weitergabe ins Ausland, einschließlich Ländern außerhalb des Europäischen Wirtschaftsraums (EWR), beinhalten. Biogen ergreift jedoch geeignete Maßnahmen, um sicherzustellen, dass deine Daten bei einer Übermittlung in solche Länder ausreichend geschützt sind. Die Schweiz beispielsweise wurde von der Europäischen Kommission als Land eingestuft, dessen Datenschutzgesetze ein angemessenes Datenschutzniveau sicherstellen. Um ein angemessenes Datenschutzniveau zu gewährleisten, wird Biogen ansonsten auf EU-Standardvertragsklauseln oder gleichwertige Maßnahmen zurückgreifen. Biogen wird dir auf Verlangen eine Liste sämtlicher Empfänger deiner personenbezogenen Daten und/oder weitere Informationen zu etwaigen Verträgen zum Datentransfer, die mit Empfängern außerhalb des EWR geschlossen wurden, zukommen lassen. `}
		/>
		<Headline as="h4" text="Speicherung deiner personenbezogenen Daten" />
		<Text content="Wir werden deine personenbezogenen Daten nur so lange aufbewahren, wie dies für den Zweck, zu dem diese Daten erhoben wurden, erforderlich und gesetzlich zulässig ist. Wenn wir deine personenbezogenen Daten nicht mehr benötigen, werden wir sie aus unseren Systemen und Aufzeichnungen löschen und/oder Maßnahmen ergreifen, um deine Daten zu anonymisieren, sodass du nicht länger durch diese identifiziert werden kannst (es sei denn, wir müssen deine personenbezogenen Daten aufbewahren, um gesetzliche oder behördliche Auflagen zu erfüllen)." />
		<Headline as="h4" text="Erteilung und Widerruf deiner Einwilligung" />
		<Text content="Da unsere Website für Patienten gedacht ist, werden wir deine Gesundheitsdaten ab dem Moment verarbeiten, an dem du dich registrierst und mit der Nutzung der Website beginnst. Aus diesem Grund benötigen wir laut dem Datenschutzgesetz deine ausdrückliche Einwilligung in diese Datenverarbeitung, wie in diesem Datenschutzhinweis ausführlich dargelegt wird. Du willigst ein, indem du bei der Registrierung das entsprechende Kästchen aktivierst. Du kannst deine Einwilligung widerrufen, indem du dich per E-Mail (info@ms-service-center.de) oder telefonisch (+49 800 030 77 30, kostenfrei) an das MS Service-Center wendest. Wenn du deine Einwilligung widerrufst, werden wir dein Nutzerkonto löschen. Du kannst die Website dann nicht mehr nutzen." />
		<Text content="Du kannst in den Empfang von E-Mails einwilligen, die für dich von Interesse sein könnten. Einzelheiten sind oben im Abschnitt „Marketing-Materialien“ dargelegt. Ob du einwilligst, ist allein deine Entscheidung. Wenn du nicht einwilligst, kannst du die Webseite zwar nutzen, jedoch keine derartigen E-Mails empfangen. Du kannst deine Einwilligung widerrufen, indem du dich per E-Mail (info@ms-service-center.de) oder telefonisch (+49 800 030 77 30, kostenfrei) an das MS Service-Center wendest. Wenn du deine Einwilligung widerrufst, werden wir dir keine derartigen E-Mails mehr zusenden." />
		<Text content="Der Widerruf deiner Einwilligung hat keinerlei Auswirkungen auf die Rechtmäßigkeit der Datenverarbeitung bis zum Zeitpunkt deines Widerrufs." />
		<Headline as="h4" text="Deine Rechte" />
		<Text content="Du kannst dich jederzeit an Biogen wenden, um die folgenden Rechte auszuüben, die dir laut Datenschutzgesetz zustehen:" />
		<ol>
			<li>
				deine personenbezogenen Daten einsehen und empfangen oder Informationen zu den personenbezogenen Daten,
				die wir zu dir aufbewahren, anfordern
			</li>
			<li>
				die Einschränkung der Verarbeitung deiner personenbezogenen Daten verlangen oder unserer Verarbeitung
				widersprechen
			</li>
			<li>die Bereitstellung deiner personenbezogenen Daten auf einem Datenträger verlangen und</li>
			<li>die Berichtigung oder Löschung von Daten verlangen, die wir zu dir aufbewahren </li>
		</ol>
		<Text content="Beachte bitte, dass einige dieser Rechte durch die anwendbaren Datenschutzbestimmungen eingeschränkt sind und wir zur Erfüllung unserer gesetzlichen Pflichten dazu berechtigt sind, deine personenbezogenen Daten zu erheben, zu verarbeiten und zu besitzen. Wenn du glaubst, dass deine Datenschutzrechte verletzt wurden, kannst du bei deiner vor Ort zuständigen Datenschutzbehörde Beschwerde einlegen, falls du dies für erforderlich erachtest." />
		<Headline as="h4" text="Ansprechpartner bei Biogen" />
		<Text
			content={`Im Sinne des europäischen Datenschutzrechts ist ein „Verantwortlicher“ eine juristische Person, die dafür verantwortlich ist, deine personenbezogenen Daten zu schützen und dich dabei zu unterstützen, deine Datenschutzrechte wahrzunehmen.  In Zusammenhang mit deinen personenbezogenen Daten, die du auf der Website angibst, ist Biogen der Verantwortliche. Der EU-Datenschutzvertreter von Biogen ist die Biogen GmbH, Riedenburger Straße 7, 81677 München, Deutschland. Falls du Fragen oder Bedenken zu diesem Datenschutzhinweis oder zur Verarbeitung deiner personenbezogenen Daten hast, kannst du dich unter der <a href="mailto:privacy@biogen.com" >privacy@biogen.com</a> jederzeit an den Datenschutzbeauftragten von Biogen wenden.`}
		/>
	</>
);

export const agreementText =
	'Ich willige ein, dass meine personenbezogenen Daten von Biogen, wie in diesem Datenschutzhinweis dargelegt, verarbeitet werden. ';
export const newsletterText =
	'Ich willige ein, dass Biogen mir – wie in diesem Datenschutzhinweis dargelegt – regelmäßige E-Mails schickt. Ich kann mich jederzeit vom Empfang solcher E-Mails abmelden. ';
