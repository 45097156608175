import React from 'react';
import * as Yup from 'yup';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import SubmitForm from '../SubmitForm/SubmitForm';
import Column from '../../Layout/Grid/Column';
import Row from '../../Layout/Grid/Row';
import Button from '../../Elements/Button/Button';

import {forms} from '../../../assets/language/de';
import {privacyPolicy, agreementText, newsletterText} from '../../../assets/language/privacyPolicy.lang';
import {overwriteInitialValues} from '../../../utils/formHelpers';
import {userDataUpdate} from '../../../state/actions/userManagement';

import {FormWrapper, SubmitWrapper, TextWrapper, StyledCheckBox} from './styles';

const {validationMessages} = forms.Register;

const AgreementForm = ({onSubmit, user}) => {
	if (!user) {
		return null;
	}

	const normalizeUser = {
		Agreement: user.Agreement,
		Newsletter: user.Newsletter,
	};

	const initialValuesForPersonalData = {
		Agreement: user.Agreement,
		Newsletter: user.Newsletter,
	};

	const validationSchema = Yup.object().shape({
		Agreement: Yup.string().min(1, validationMessages.forename.ifEmpty),
	});

	const handleOnSubmit = (values) => {
		const normalizeValues = {
			Agreement: values.Agreement,
			Newsletter: values.Newsletter,
		};
		onSubmit(normalizeValues);
	};

	return (
		<>
			<TextWrapper>
				<Column xs={10} lg={10} offset={{xs: 1, lg: 1}}>
					{privacyPolicy}
				</Column>
			</TextWrapper>
			<FormWrapper>
				<SubmitForm
					initialValues={overwriteInitialValues(initialValuesForPersonalData, normalizeUser)}
					validationSchema={validationSchema}
					onSubmit={handleOnSubmit}
					renderInnerForm={(formikProps) => (
						<Row>
							<Column lg={10} offset={{lg: 1}}>
								<Row>
									<Column lg={6}>
										<StyledCheckBox label={agreementText} name="Agreement" />
									</Column>
									<Column lg={6}>
										<StyledCheckBox label={newsletterText} name="Newsletter" />
									</Column>
								</Row>
								<SubmitWrapper>
									<Button
										isSubmitting={formikProps.isSubmitting}
										type="submit"
										text="Zum Profil speichern"
									/>
								</SubmitWrapper>
							</Column>
						</Row>
					)}
				/>
			</FormWrapper>
		</>
	);
};

AgreementForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	user: PropTypes.shape({
		UserId: PropTypes.string,
		Agreement: PropTypes.bool,
		Newsletter: PropTypes.bool,
	}).isRequired,
};

const mapStateToProps = (state) => ({
	user: state.userManagement && state.userManagement.user,
});

const mapDispatchToProps = (dispatch) => ({
	onSubmit: (data) => dispatch(userDataUpdate(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AgreementForm);
