import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {navigate} from 'gatsby';

import Layout from '../components/Layout/Layout';
import AgreementForm from '../components/Container/AgreementForm';
import Section from '../components/Layout/Section/Section';
import {PageTemplateStyled, PageSectionStyled} from '../templates/styles';

const Datenschutz = ({location, user}) => {
	useEffect(() => {
		if (!user || location?.state?.to === '/community') {
			navigate('/login');
		}
	}, [user]);

	if (!user || location?.state?.to === '/community') {
		return null;
	}

	return (
		<Layout location={location} isTabsShow={false}>
			<PageTemplateStyled>
				<PageSectionStyled>
					<Section>{user && <AgreementForm />}</Section>
				</PageSectionStyled>
			</PageTemplateStyled>
		</Layout>
	);
};

Datenschutz.propTypes = {
	location: PropTypes.shape({pathname: PropTypes.string, state: PropTypes.shape({to: PropTypes.string})}).isRequired,
	user: PropTypes.shape({
		Agreement: PropTypes.bool,
	}).isRequired,
};

const mapStateToProps = (state) => ({
	user: state.userManagement.user,
});

export default connect(mapStateToProps)(Datenschutz);
